
.dropdown-container {
  position: relative;
  width: 200px;
  margin: 0 auto; /* Mantiene el centrado */
  top: 41px;
  display: block;
  transition: transform 0.3s ease;
}

.dropdown-container.expanded {
  transform: translateX(-30px);
}

     .dropdown-header {
        background-color: #ffffff;
        padding: 1px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        width: auto;
      }
     .dropdown-arrow {
        transition: transform 0.3s ease;
        font-weight: bold;
        font-size: 1.2em;
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
      }
     .dropdown-arrow.open {
       transform: rotate(180deg);
     }
     .dropdown-list {
       position: absolute;
       top: 35px;
       right: 3px;
       width: 220px;
       height: 290px;
       background-color: white;
       border: 1px solid #b7d8f0;
       box-shadow: 0 4px 8px rgba(139, 173, 191, 0.1);
       z-index: 1000;
       overflow-y: auto;
       border-radius: 6px;
     }

     .dropdown-search {
           width: calc(92% - 20px);
           padding: 8px;
           margin: 5px 10px;
           border: 1px solid #b7d8f0;;
           border-radius: 4px;
         }
     .dropdown-item {
      color: black;
       padding: 10px;
       cursor: pointer;
     }
     .dropdown-item:hover {
       background-color: #f0f0f0;
     }
     .pagination-controls {
       display: flex;
       justify-content: space-between;
       padding: 10px;
       
     }
    