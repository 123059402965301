.cuarteto-group {
    display: flex;
    flex-direction: column;
    border: 1px solid #beeaf8;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 200px; /* Fijar un ancho para que los grupos se alineen */
  }
  
  .cuarteto-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .cuarteto-header span {
    font-size: 14px;
  }
  
  .cuarteto-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: left; /* Asegura que el texto esté alineado a la izquierda */
  }
  
  .question-text {
    flex: 1;
    padding-right: 10px;
    text-align: left; /* Alinea el texto a la izquierda */
    font-size: 14px;
  }
  
  .cuarteto-inputs {
    display: flex;
    justify-content: space-around;
    width: 80px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 7px;
  }
  
  .input-group label {
    margin-bottom: 3px;
  }
  
  .input-group input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
  }
  
  .input-group input[type="radio"]:checked {
    border-color: #313C44;
  }
  
  .input-group input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #313C44;
    position: absolute;
    top: 2px;
    left: 2px;
  }
  
  .input-group input[type="radio"]:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  
  