.grid-container {
    display: flex;
    grid-template-rows: auto auto;
    grid-gap: 2px;
    justify-content: center; /* Alinea los items en el centro horizontalmente */
    align-items: center; /* Alinea los items en el centro verticalmente */
    height: 80vh; /* Establece el alto del contenedor al 100% de la altura visible de la ventana */
    overflow-y: auto;
  }
  

  .grid-item {
    width: 30%;
    padding: 2px;
  }
  
  .componente1 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.0);
  }
  
  .componente2 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  .componente3 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  
  .componente4 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }

  .close-button-terminos {
    position: absolute;
    top: -88px;
    right: 30px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 9999;
    color: rgb(255, 255, 255);
    font-size: 20px;
    opacity: 1; /* Asegura que el botón sea visible */
    pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
  }

  .button-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: transparent;
    color: black;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .button-link:hover {
    background-color: transparent;
    color: grey;;
    text-decoration: none; /* Asegurar que no haya subrayado al hacer hover */
  }
  
  .button-link:focus {
    outline: none;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      flex-direction: column;
    }

  }

  @media (max-width: 768px) {
    .grid-item {
        width: 90%;
        padding: 1px;
      }
      .componente1 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  
  .componente2 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  .componente3 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  
  .componente4 {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px -2px 5px rgba(1, 0, 0, 0.1);
  }
  .grid-container {
    display: flex;
    grid-template-rows: auto auto;
    grid-gap: 2px;
    justify-content: center; /* Alinea los items en el centro horizontalmente */
    align-items: center; /* Alinea los items en el centro verticalmente */
    height: 100vh; /* Establece el alto del contenedor al 100% de la altura visible de la ventana */
    overflow-y: auto;
  }

  }