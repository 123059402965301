

.input-container-text {
    position: fixed;
    bottom: 45px;
    left: 49%;
    transform: translateX(-49%);
    
    width: 51%;
    background-color: transparent;
    border-radius: 16px;
    padding: 8px;
    z-index: 1000;
    transition: margin-left 0.3s ease;
  }


  .input-container-text.expanded {
    margin-left: -30px;
  }
  
  .auto-resize-textarea-chat {
    width: 100% !important;
    padding: 12px 0px 12px 6px !important; /* Más padding a la derecha para el botón */
    resize: none !important;
    overflow-y: auto !important;
    min-height: 44px !important;
    max-height: 270px !important;
    border-radius: 16px !important;
    border: 1px solid #f2efef !important;
    background: #ffffff !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  }

  .auto-resize-textarea-chat:focus {
    border: 1px solid #4a6e7e !important; /* Borde gris oscuro */
    outline: none !important;
}

.auto-resize-textarea-chat:focus-visible {
    outline: none !important;
}

  
  .send-button {
    position: absolute !important;
    right: 4px !important;
    bottom: 16px !important;
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    padding: 6px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #666 !important;
    transition: color 0.3s ease !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 28px !important;
    height: 28px !important;
  }


  .send-button:focus {
    outline: none !important;
    box-shadow: none !important;
    background: #c7dcf3 !important;
    border: none !important;
  }
  
  .send-button:focus-visible {
    outline: 2px solid rgba(220, 226, 233, 0.5) !important;
    outline-offset: 2px !important;
  }
  .send-button:hover {
    color: #007bff !important;
    background: #c7dcf3 !important;
  }
  
  .send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


  .auto-resize-textarea-chat-trained {
    width: 100% !important;
    padding: 12px 0px 12px 6px !important; /* Más padding a la derecha para el botón */
    resize: none !important;
    overflow-y: auto !important;
    min-height: 44px !important;
    max-height: 270px !important;
    border-radius: 16px !important;
    border: 1px solid #f2efef !important;
    background: #f6fafb !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  }

  .input-titulo-trained {
    width: 100% !important;
    padding: 12px 0px 12px 6px !important; /* Más padding a la derecha para el botón */
    resize: none !important;
    overflow-y: auto !important;
    min-height: 34px !important;
    max-height: 270px !important;
    border-radius: 16px !important;
    border: 1px solid #f2efef !important;
    background: #f6fafb !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  }



  .send-button-trained {
    position: absolute !important;
    right: 24px !important;
    bottom: 102px !important;
    background: transparent !important;
    border: none !important;
    cursor: pointer !important;
    padding: 6px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #666 !important;
    transition: color 0.3s ease !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 28px !important;
    height: 28px !important;
  }

  .send-button-trained:focus {
    outline: none !important;
    box-shadow: none !important;
    background: #c7dcf3 !important;
    border: none !important;
  }
  
  .send-button-trained:focus-visible {
    outline: 2px solid rgba(220, 226, 233, 0.5) !important;
    outline-offset: 2px !important;
  }
  .send-button-trained:hover {
    color: #007bff !important;
    background: #c7dcf3 !important;
  }
  
  .send-button-trained:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
