.dropdown-container2 {
    position: relative;
    width: 200px;
    margin: 0 auto;
    top: 1px;
    display: block;
  }
  
  .dropdown-header2 {
    background-color: #ffffff;
    padding: 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
  }
  
  .dropdown-arrow {
    transition: transform 0.3s ease;
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
  }
  
  .dropdown-arrow.open {
    transform: rotate(180deg);
  }
  
  .dropdown-list2 {
    position: absolute;
    top: 45px;
    width: 100%;
    height: 180px;
    background-color: white;
    border: 1px solid #b7d8f0;
    box-shadow: 0 4px 8px rgba(139, 173, 191, 0.1);
    z-index: 1000;
    overflow-y: auto;
    border-radius: 6px;
  }
  
  .dropdown-search2 {
    width: calc(90% - 20px);
    padding: 8px;
    margin: 5px 10px;
    border: 1px solid #b7d8f0;
    border-radius: 4px;
  }
  
  .dropdown-item2 {
    color: black;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .pagination-controls2 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  
  .selected-users-container2 {
    width: 80%;
    margin-top: 60px;
    padding: 10px;
    border: 1px solid #b7d8f0;
    border-radius: 4px;
    background-color: #f1f6f9;
  }
  
  .selected-users2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .selected-user2 {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 5px 10px;
    max-width: fit-content;
  }
  
  .user-name2 {
    margin-right: 10px;
    font-size: 14px;
  }
  
  .remove-user2 {
    background-color: transparent;
    border: none;
    color: #95c9ee;
    font-size: 12px;
    cursor: pointer;
  }
  
  .remove-user2:hover {
    color: #cc0000;
  }
  