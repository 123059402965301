/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #333;
  margin: 0;
  padding: 0;
}

.containerColum {
  display: flex;
  gap: 40px;
  justify-content: center; /* Centra horizontalmente las columnas */
  align-items: flex-start; /* Alinea al tope para mantener altura consistente */
  min-height: 100vh; /* Ocupa toda la altura de la pantalla */
  padding: 40px 20px;
  margin: 0 auto;
  max-width: 1400px; /* Ancho máximo según necesidad */
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease;
 
}

/* Títulos */
.title {
  font-size: 24px;
  font-weight: 600;
  color: #444;
  margin-bottom: 20px;
}

/* Inputs */
.input {
  width: 96%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1px;
  font-size: 14px;
  color: #333;
}

.inputDropdown{
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333;

}

.input:focus {
  outline: none;
  border-color: #999;
}

/* Botones */
.button {
  padding: 5px 20px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #444;
}

.buttonSave {
  padding: 5px 20px;
  background-color: #6cbc7f;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttonSave:hover {
  background-color: #444;
}

.button-primary {
  background-color: #555;
}

.button-save {
  background-color: #80c790;
}
.button-save:hover {
  background-color: #23572e;
}

.button-primary:hover {
  background-color: #444;
}

/* Listas */
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-item:hover {
  background-color: #e1e1e1;
}

/* Mensajes de carga y error */
.loading {
  color: #777;
  text-align: center;
  margin-top: 20px;
}

.error {
  color: #d9534f;
  text-align: center;
  margin-top: 20px;
}

/* Espaciado */
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
  overflow-y: auto;
}

.mt-20 {
  margin-top: 20px;
}


/* Estilos adicionales para ResponseList */
.response-details {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.button-close {
  margin-top: 10px;
}

/* Estilos para el historial */
.history-container {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  
}

.history-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: auto;
}

.history-item {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.history-item pre {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

/* Contenedor con scroll para las respuestas */
.responses-list {
  max-height: 300px; /* Altura máxima del contenedor */
  overflow-y: auto;  /* Scroll vertical */
  padding-right: 10px; /* Espacio para el scroll */
}

/* Estilos para cada tarjeta de respuesta */
.responses-list {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.response-item.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  background: #fafafa;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.response-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.response-date {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

.response-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.response-line {
  display: flex;
  gap: 0.5rem;
  font-size: 0.95rem;
}

.field-label {
  font-weight: bold;
  color: #555;
}

.field-value {
  color: #333;
}

.no-responses {
  text-align: center;
  color: #777;
  font-style: italic;
}


.form-column,
.history-column {
flex: 0 1 600px; /* No crecer, encoger hasta 600px */
background: #fff;
padding: 25px;
border-radius: 12px;

}

/* Notificación flotante */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

/* Ajustes para inputs controlados */
.inputDropdown {
  width: 100%;
}

/* Para móviles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center; /* Centrar columnas en móvil */
    padding: 20px;
  }
  
  .form-column,
  .history-column {
    width: 100%;
    max-width: 600px;
  }
}

/* Search container */
.search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-container input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

/* Search results */
.search-results {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
}

.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}


.field-container {
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  
}

.delete-button {
  background: #ffffff !important;
  color: #e56e3e !important;
  border: 2px solid #ffffff !important;
  border-radius: 50% !important;
  width: 28px;
  height: 28px;
  font-weight: bold;
  transition: 0.3s;
  position: relative;
}
/* Tooltip */
.delete-button::after {
  content: "Borrar";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #333333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
  white-space: nowrap;
  font-family: Arial, sans-serif;
}

.delete-button:hover::after {
  opacity: 1;
}

.delete-button:hover {
  background: #e39c9c !important;
  color: white !important;
  border-color: #514c4c !important;
}
.auto-resize-textarea {
  resize: none;
  overflow-y: auto; /* Cambiado de hidden a auto */
  min-height: 40px;
  max-height: 250px;
  line-height: 1.5;
  padding: 8px 12px;
  
  /* Scroll transparente */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: transparent transparent; /* Para Firefox */
}

/* Scrollbar para WebKit (Chrome, Safari) */
.auto-resize-textarea::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.auto-resize-textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.auto-resize-textarea:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Eliminar bordes de enfoque */
.auto-resize-textarea:focus {
  outline: none;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #cccccc !important;
}

.button:disabled.button-primary {
  background-color: #98a0a9 !important;
}

.checkbox-preview {
  margin-right: 8px;
  cursor: default;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.checkbox-label {
  font-size: 16px;
  color: #333;
}

/* Contenedor principal de botones de agregar campos */
.add-fields-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

/* Grupo horizontal de botones */

/* Flexbox */
.flex {
  display: flex;
  gap: 5px;
}

select.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.container::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Para Firefox */
.container {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}


.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

/* Nuevos estilos para el sidebar */
.containerColum {
  display: flex;
  height: 100vh;
}

/* Sidebar y toggle */
.sidebar {
  width: 250px;
  background: #ffffff;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  padding: 20px;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  margin: 0;
}



.sidebar.closed {
  transform: translateX(-100%);
  border-right: none;
}

.sidebar-toggle {
  /* Elimina los estilos anteriores y mantén solo los de base */
  all: unset; /* Reset de estilos por defecto */
}

.sidebar-toggle:active {
  transform: scale(0.95) !important;
}


/* styles.css */
.sidebar-content {
  padding: 15px;
}

.list-item {
  cursor: pointer;
  padding: 5px;
  margin: 5px 0;
  background: #fff;
  border-radius: 6px;
  transition: all 0.2s;
}

.list-item:hover {
  background: #e3f2fd;
  transform: translateX(5px);
}

.list {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.form-builder-container {
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.main{
  transition: margin-left 0.3s ease;
}

.form-builder-container.expanded {
  margin-left: -90px;
}

.spinner-container.expanded {
  margin-left: -90px;
  transition: margin-left 0.3s ease;
}

.spinner-container {
 
  transition: margin-left 0.3s ease;
}

/* Ajustes para móvil */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    z-index: 1000;
    left: 0;
  }
  
  .sidebar-toggle {
    left: 10px !important;
    top: 10px !important;
  }
  
  .sidebar-toggle.collapsed {
    left: 10px !important;
  }
  
  .sidebar.closed {
    transform: translateX(-100%);
  }
  
  .form-builder-container.expanded {
    margin-left: 0;
  }
}