.loading-text {
    font-size: 1.2em;
    font-weight: normal;
    background: linear-gradient(90deg, #303c44, #e1ecf4, #b6e9f9);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientAnimation 3s ease infinite;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}
