.posts-container {
    text-align: left;
    height: 40vh;
    max-height: 70%;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 14px;
  }
  
  .post-item {
    position: relative;
    display: inline-block;
    padding: 4px;
  }
  
  .post-link {
    text-decoration: none;
    color: rgb(10, 9, 9);
    font-weight: normal;
  }
  
  .post-link.active {
    color: black;
    font-weight: bold;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  
  .pagination-controls button {
    border: none;
    background-color: white;
    padding-top: 15px;
  }

  .pagination-controls span {
    border: none;
    background-color: white;
    padding-top: 14px;
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .spinner img {
    width: 50px;
    height: 50px;
  }

  
  
  .dropdownH-menu button {
    
    padding: 8px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    text-align: left;
    z-index: 998;
    font-size: 14px;
  }
  
  .dropdownH-menu button:hover {
    background-color: #f0f0f0;
    color: black;
  }
  
  
  .dropdownH-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 5px; /* Ajusta el padding según sea necesario */
    transition: font-weight 0.3s ease; /* Transición suave para el cambio de peso de fuente */
    opacity: 0; /* Oculto por defecto */
    pointer-events: none; /* Desactiva los eventos de ratón */
  }
  
  .post-item:hover .dropdownH-toggle {
    opacity: 1; /* Visible al hacer hover */
    pointer-events: auto; /* Activa los eventos de ratón */
    
  }
  
  .vertical-dots::before {
    content: '⋮'; /* El carácter Unicode para tres puntos verticales */
    font-size: 24px; /* Ajusta el tamaño según sea necesario */
    margin-left: 25px;
  }
  
  .dropdownH-toggle:hover .vertical-dots::before {
    font-weight: bold;
  }
  