.survey-container {
    display: flex;
    flex-direction: column; /* Alinea los elementos en una columna */
    justify-content: center; /* Centra los elementos verticalmente si hay espacio adicional */
    align-items: center; /* Centra los elementos horizontalmente */
    text-align: center; /* Alinea el texto dentro de cada elemento */
    
  }
  
.survey-step {
    font-size: 20px;
    margin-bottom: 20px;
}

.survey-steps {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.step-dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #beeaf8;
    border-radius: 50%;
    display: inline-block;
}

.step-dot.completed {
    background-color: #313C44;
}

.survey-content {
    
    align-items: center;
}

.input-step {
    width: 500px; 
}

.input-step label {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.input-step input {
    padding: 8px;
    font-size: 16px;
    width: 100%;
}

.survey-groups {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    width: 700px; 
}

.survey-group {
    display: flex;
    flex-direction: column;
    border: 1px solid #beeaf8;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 200px;
}


.survey-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.survey-header span {
    font-size: 14px;
}

.survey-item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    text-align: left;
}

.question-text {
    flex: 1;
    padding-right: 10px;
    text-align: left;
    font-size: 14px;
}

.survey-inputs {
    display: flex;
    justify-content: space-around;
    width: 80px;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 7px;
}

.input-group label {
    margin-bottom: 3px;
}

.input-group input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

.input-group input[type="radio"]:checked {
    border-color: #313C44;
}

.input-group input[type="radio"]:checked::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #313C44;
    position: absolute;
    top: 2px;
    left: 2px;
}

.survey-navigation {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.survey-navigation .navi-button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #313C44;
    color: white;
    border: none;
    border-radius: 5px;
}

.survey-navigation .navi-button:hover,
.survey-navigation .navi-button:focus {
    background-color: #beeaf8 !important;
    border: none;
    color: #313C44;
}

.navi-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.navi-button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #313C44;
    color: white;
    border: none;
    border-radius: 5px;
}

.survey-scores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.survey-scores p {
    margin: 0 10px;
}

.warningLink {
    background-color: #fffef2; /* Fondo amarillo suave */
    color: #330202; /* Color de texto rojo oscuro */
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 20px;
    border-left: 7px solid #beeaf8; /* Borde izquierdo naranja */
    font-weight: 300; /* Fuente más fina */
    font-size: 16px; /* Tamaño de fuente */
    width: 490px; 
    margin-bottom: 20px; /* Separación respecto al siguiente elemento */
  }
  

  .exitoLink {
    background-color: #f3fbf4; /* Fondo amarillo suave */
    color: #3b4f30; /* Color de texto rojo oscuro */
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 20px;
    border-left: 7px solid #beeaf8; /* Borde izquierdo naranja */
    font-weight: 300; /* Fuente más fina */
    font-size: 16px; /* Tamaño de fuente */
    width: 490px; 
    margin-bottom: 20px; /* Separación respecto al siguiente elemento */
  }

  .info {
    font-weight: 300; /* Fuente más fina */
    font-size: 14px; /* Tamaño de fuente */
  }

  .icon-text-container {
    display: flex;
    align-items: center;
    cursor: pointer; /* Hace que el puntero se vea como un dedo cuando pasa sobre el ícono y el texto */
}


.volver-text {
    margin-left: 7px; /* Ajusta el valor para controlar el espacio entre el icono y el texto */
}

.chat-icon-container {
    display: inline-flex;  /* Para alinear el icono y el texto horizontalmente */
    align-items: center;   /* Para alinear verticalmente el icono y el texto */
    cursor: pointer;       /* Hace que el puntero del mouse se vea como un dedo */
    margin-left: 10px;     /* Espacio entre el texto principal y el icono de chatear */
}

.chat-icon-container p {
    margin-left: 10px; /* Ajusta el espacio alrededor del texto "Chatear" */
    font-size: 14px; /* Tamaño de fuente */
}
