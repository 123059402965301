/* Chat.css */


body {
  overflow-x: hidden; /* Oculta la barra de desplazamiento horizontal en el cuerpo */
  overflow-y: hidden; /* Oculta la barra de desplazamiento vertical */
}

/* Barra de menú flotante superior */
.floating-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

/* Estilos generales */
.chat-container {
  position: relative;
  height: 80vh;
  overflow-y: auto; /* Oculta la barra de desplazamiento vertical */
 
}

.sidebar {
  position: fixed;
  top: 0px;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #ffffff;
  padding: 20px;
  overflow-y: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  transform: translateX(0);
}
.sidebar.close {
  transform: translateX(-200px); /* Mueve el sidebar fuera de la pantalla hacia la izquierda */
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
  color: #7f8083;
}

.sidebar-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(4, 4, 4);
  bottom: 0;
}

.sidebar span {
  background: none;
  border: none;
  cursor: pointer;
  color: #141414;
  font-size: 12px;
  
}

.messages {
  max-height: calc(95vh - 150px);
  overflow-y: auto;
  background-color: transparent;
}

.messages::-webkit-scrollbar {
  width: 0; /* Ancho de la barra de desplazamiento */
  background: transparent; /* Color de fondo de la barra, opcional */
}
.messages {
  scrollbar-width: none; /* Oculta la barra de desplazamiento */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 5px;
  max-width: 90%;
  position: relative;  /* Asegura que z-index funcione */
  background-color: transparent;
  text-align: justify;
  text-underline-position: auto;
  font-size: 16px; /* Tamaño del texto */
  line-height: 1.8; /* Espacio entre líneas */
  color: #545557;
}


.user {
  align-self: flex-end;
  background: transparent;
  color: #000000;
  text-decoration: solid;
}
.chat-img-user {
  width: 31px; /* Tamaño de la imagen del perfil */
  height: 31px;
  border-radius: 2%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
  padding-right: 8px;
}
.chat-img-bot {
  width: 38px; /* Tamaño de la imagen del perfil */
  height: 38px;
  border-radius: 2%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
  padding-right: 8px;
}

.bot {
  align-self: flex-start;
  background-color: transparent;
  color: #272727;
}

/* Estilos para opciones de mensaje */
.message-options {
  position: absolute;
  top: 55px;
  right: -8px; /* Ajusta la posición de las opciones */
}


.pregunta-options-button {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.respuesta-options-button {
  margin-left: 1px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

/* Estilos para el botón de descarga */
.message-options button.download {
  color: #007bff; /* Color del botón */
}

.message-options button.download:hover {
  color: #0056b3; /* Color del botón al hacer hover */
}

.main {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px; /* Ancho máximo para mantener proporción */
  background: transparent !important;
  padding: 20px 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  font-size: 10px;
  transition: margin-left 0.3s ease;
}
.main.expanded {
  margin-left: -30px;
}

.main a{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 700px; /* Ancho máximo para mantener proporción */
  padding: 2px 1px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  transition: margin-left 0.3s ease;
}

.button-link2 {
  font-size: 12px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  background: linear-gradient(90deg, black 5%, #0f100f 60%, #1c1c1b 15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main2 {
  position: fixed;
  top: 68px;
  left: 53%; /* Establecer el punto de partida en el centro horizontal */
  transform: translateX(-53%); /* Desplazar el elemento a la izquierda en un 50% de su ancho */
  width: 50%;
  height: 60%; /* Alto del 70% */
  background-color: transparent;
  padding: 10px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.0);
  transition: margin-left 0.3s ease;
}

.main2.expanded {
  margin-left: -30px;
}

.main input[type="text"] {
  width: calc(100% - 70px);
  padding: 8px;
  margin-right: 10px;
  position:'fixed';
}

.main input[type="email"] {
  width: calc(100% - 70px);
  padding: 8px;
  margin-right: 10px;
  position:'fixed';
}

.main button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.main button:hover {
  background-color: #5a5b5e;
}

/* Estilos para el botón flotante de scroll al fondo */
.scroll-to-bottom {
  position: fixed;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra el botón */
  background-color: #ffffff;
  color: #141414;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: margin-left 0.3s ease;
}

.scroll-to-bottom.expanded {
  margin-left: -30px;
}

.scroll-to-bottom:hover {
  background-color: #545557;
  color: #ffffff;
}

.scroll-to-bottom svg {
  font-size: 19px;
}

/* Estilos para el dropdown */
.dropdown {
  position: fixed;
  top: 30px;
  right: 120px;
  background-color: transparent;
  z-index: 998;
  
}

.dropdown button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  flex-grow: 1; /* Ajusta el contenido del menú a la izquierda */
  z-index: 998;
  
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: -40px; /* Posiciona el dropdown a la derecha */
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 998;
  border: 1px solid #e2e2e2;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  text-align: left;
  align-items: center;
  z-index: 998;
  font-size: 14px;
}

.dropdown-menu span {
  
  padding: 3px 8px;
  
 
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}
.login-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: none;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Agrega una sombra para resaltar el botón */
}

.login-button:hover {
  background-color: #5a5b5e;
}


.input-container {
  display: flex;
  width: 100%;
}

.input-container input {
  flex: 1;
  padding: 18px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input-container form {
  flex: 1;
  padding: 18px;
  
  border-radius: 10px;
}

.input-container button {
  background-color: #ffffff;
  color: rgb(45, 45, 45);
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}

.input-container button:hover {
  background-color: #545557;
  color: #ffffff;
}
.button-container {
  display: flex;
  justify-content: space-between; /* Espaciado uniforme entre los botones */
  margin-top: 3px; /* Margen superior para separar los botones del input */
}

.button-container button {
  background: none;
  border: none;
  cursor: pointer;
  color: #414142;
  
}

.button-container button p {
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 3px; /* Espacio entre el icono y el texto */
  color: #414142;
 
}

.disclaimer-base-container {
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-top: 13px; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.disclaimer-base-container-terminos {
  position: fixed;
  bottom: 0;
  font-size: 10px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  margin-left: 47%; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.disclaimer-base-container-suscripcion {
  position: fixed;
  bottom: 0;
  font-size: 16px; /* Tamaño de letra pequeña */
  margin-bottom: 6px;
  margin-left: 47%; /* Espacio entre el icono y el texto */
  text-align: center;
  color: #1c1c1d;
  z-index: 9999;
}

.button-container button svg {
  margin-right: 3px; /* Espacio entre el icono y el texto */
}

.sidebar-buttons button span {
  font-size: 12px; /* Tamaño de letra pequeña */
  margin-top: 3px; /* Espacio entre el icono y el texto */
  background-color: transparent;
}

.sidebar-content {
  position: fixed;
  top: 10%;
  
}

.sidebar-content p {
  color: #8b8b8b;
}

.profile-container {
  position: fixed;
  top: 13px;
  right: 90px;
  background-color: transparent;
  z-index: 999;
}

.sidebar-buttons {
  position: fixed;
  top: 10px;
  right: 35px;
  background-color: transparent;
}

.button-text {
  font-size: 12px;
  margin: 0;
}

.sidebar-button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 30px; /* Separación del historial */
}

.profile-container img {
  width: 30px; /* Tamaño de la imagen del perfil */
  height: 30px;
  border-radius: 50%; /* Para que la imagen tenga forma circular */
  background-color: transparent;
}



.profile-container h5 {
  margin-top: 5px; /* Espacio entre la imagen y el nombre */
  font-size: 14px; /* Tamaño del nombre del usuario */
  color: #7f8083; /* Color del nombre del usuario */
  background-color: transparent;
}

.floating-menu h4 {
  position: fixed;
  right: 47%;
  color: #414142;
}
.floating-menu-logo {
  position: fixed;
  width: 155px; /* Tamaño de la imagen del perfil */
  height: 35px;
  margin-left: 20px;
  top: 6px;
  z-index: 997;
  padding-right: 10px;
  transition: margin-left 0.3s ease;
}

.floating-menu-logo.collapsed {
  margin-left: 20px;
}

.floating-menu-logo-2 {
  position: fixed;
  width: 155px; /* Tamaño de la imagen del perfil */
  height: 35px;
  margin-left: 240px;
  top: 6px;
  z-index: 997;
  padding-right: 10px;
  transition: margin-left 0.3s ease;
}

.floating-menu-logo-2.collapsed {
  margin-left: 40px;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}


.contexto-container {
  display: block;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 60vh;
}

.contexto-container h2 {
  color: #333333; /* Gris oscuro */
}

.contexto-container p {
  color: #868585; /* Gris claro */
}

.contexto-container button {
  background-color: #000000; /* Negro */
  color: #ffffff; /* Letras blancas */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}
.contexto-container button:hover {
  background-color: #eceaea; /* Gris claro al pasar el mouse */
  color: #000000; /* Letras blancas */
}

.contexto-container button .button-link {
  text-decoration: none;
  color: inherit; /* Inherit color from the button */
}


.buttonStyle {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  padding: '8px 16px';
  border-radius: '4px';
  background-color: #007bff;
  color: #000205;
  border: 'none';
  cursor: 'pointer';
  font-size: '26px';
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner img {
  max-width: 100%;
}



@media (max-width: 768px){

  
  .contexto-container {
    display: block;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 40vh;
  }
  
.main {
  position: fixed;
  bottom: 0;
  left: 55%; /* Establecer el punto de partida en el centro horizontal */
  width: 90%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.0);
}





.floating-menu-logo-2 {
  position: fixed;
  width: 152px; /* Tamaño de la imagen del perfil */
  height: 33px;
  right: 43%;
  top: 9px;
  z-index: 997;
  padding-right: 1px;
}
.profile-container {
  position: fixed;
  top: 13px;
  right: 30px;
  background-color: transparent;
  z-index: 999;
}
.dropdown {
  position: fixed;
  top: 30px;
  right: 53px;
  background-color: transparent;
  z-index: 998;
}

.message-options {
  position: absolute;
  top: 8px;
  right: -1px; /* Ajusta la posición de las opciones */
}
.pregunta-options-button {
 
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

.respuesta-options-button {
  
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
}

}

@media screen and (max-width: 768px) {
  .sidebar {
    transform: translateX(-200px);
  }
  .disclaimer-base-container-terminos {
    position: fixed;
    bottom: 0;
    font-size: 10px; /* Tamaño de letra pequeña */
    margin-left: 40%; /* Espacio entre el icono y el texto */
    text-align: center;
    color: #1c1c1d;
    z-index: 9999;
    
  }
  .disclaimer-base-container {
    font-size: 10px; /* Tamaño de letra pequeña */
   
    margin-right: 10%; /* Espacio entre el icono y el texto */
    color: #1c1c1d;
    z-index: 9999;
  }
}


.warning {
  background-color: #fffef2; /* Fondo amarillo suave */
  color: #f00a0a; /* Color de texto rojo oscuro */
  padding: 10px;
  border-left: 7px solid #beeaf8; /* Borde izquierdo naranja */
  font-weight: 300; /* Fuente más fina */
  font-size: 14px; /* Tamaño de fuente */
}

.info {
  font-weight: 300; /* Fuente más fina */
  font-size: 14px; /* Tamaño de fuente */
}

.contexto-link {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw; /* 100% del ancho de la pantalla */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.contexto-link.expanded {
  transform: translate(calc(-50% - 30px), -50%);
}
