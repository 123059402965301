.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper-terminos {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  top: 10px;
  width: 100%;
  height: 90%;
  background: white;
  border-radius: 10px;
  overflow: auto;
  
}
.modal-content-terminos {
  top: 10px;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  overflow: auto;
  
}


.modal-header {
  position: fixed;
  top: 50px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #1c1a1a;
  background: #313C44;
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.modal-header-terminos {
  position: fixed;
  top: 0px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #1c1a1a;
  background: #313C44;
  color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  font-size: 18;
}

.modal-body {
  font-size: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
.modal-body p {
  text-align: left; /* Justificar el texto hacia la derecha */
  max-width: 50%; /* Establecer un ancho máximo del 70% */
}


.modal-body-terminos {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
.modal-body-terminos p {
  text-align: left; /* Justificar el texto hacia la derecha */
  max-width: 50%; /* Establecer un ancho máximo del 70% */
  font-size: 18px;
}
.question-button {
  background-color: #313C44 !important;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
}
.close-button {
  position: absolute;
  top: 72px;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 9999;
  color: rgb(255, 255, 255);
  font-size: 20px;
  opacity: 1; /* Asegura que el botón sea visible */
  pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
}

.close-button-terminos {
  position: absolute;
  top: 18px;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 9999;
  color: rgb(255, 255, 255);
  font-size: 20px;
  opacity: 1; /* Asegura que el botón sea visible */
  pointer-events: auto; /* Permite que el botón sea reactivo a eventos de ratón */
}

.button-link {
  display: inline-block;
  padding: 1px 1px;
  background-color: transparent;
  color: #313C44;
  text-decoration: none;
  border-radius: 5px;
}

.button-link:hover {
  background-color: transparent;
  color: grey;;
  text-decoration: none; /* Asegurar que no haya subrayado al hacer hover */
}

.button-link:focus {
  outline: none;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .modal-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Agregar scrolling vertical al contenido */
  margin-top: 100px; /* Ajustar el valor de acuerdo a la altura del header fijo */
}
  .modal-body p {
      text-align: left; /* Justificar el texto hacia la derecha */
      max-width: 90%; /* Establecer un ancho máximo del 70% */
    }
    .modal-body-terminos p {
      text-align: left; /* Justificar el texto hacia la derecha */
      max-width: 90%; /* Establecer un ancho máximo del 70% */
      font-size: 18px;
    }

    .modal-header-terminos {
      position: fixed;
      top: 0px;  /* Ajusta el valor de acuerdo a la altura del header fijo existente */
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #1c1a1a;
      background: #8b8b8b;
      color: rgb(255, 255, 255);
      text-align: left;
      font-size: 18px;
    }
}